import { isEuVersion } from 'common/constants/env'

import translationsEN from './locales/en/common.json'
import iconFlagUS from '../images/flags/us.svg'
import translationsES from './locales/es/common.json'
import iconFlagES from '../images/flags/es.svg'
import translationsPT from './locales/pt/common.json'
import iconFlagPT from '../images/flags/pt.svg'
import translationsFR from './locales/fr/common.json'
import iconFlagFR from '../images/flags/fr.svg'
import translationsRU from './locales/ru/common.json'
import iconFlagRU from '../images/flags/ru.svg'
import translationsIT from './locales/it/common.json'
import iconFlagIT from '../images/flags/it.svg'
import translationsDE from './locales/de/common.json'
import iconFlagDE from '../images/flags/de.svg'
import translationsNL from './locales/nl/common.json'
import iconFlagNL from '../images/flags/nl.svg'
import translationsPL from './locales/pl/common.json'
import iconFlagPL from '../images/flags/pl.svg'
import translationsID from './locales/id/common.json'
import iconFlagID from '../images/flags/id.svg'
import translationsTR from './locales/tr/common.json'
import iconFlagTR from '../images/flags/tr.svg'
import translationsKO from './locales/ko/common.json'
import iconFlagKO from '../images/flags/ko.svg'
import translationsNO from './locales/no/common.json'
import iconFlagNO from '../images/flags/no.svg'
import translationsSV from './locales/sv/common.json'
import iconFlagSV from '../images/flags/sv.svg'
import translationsJP from './locales/jp/common.json'
import iconFlagJP from '../images/flags/jp.svg'
import translationsFI from './locales/fi/common.json'
import iconFlagFI from '../images/flags/fi.svg'
import translationsLV from './locales/lv/common.json'
import iconFlagLV from '../images/flags/lv.svg'
import translationsDA from './locales/da/common.json'
import iconFlagDA from '../images/flags/da.svg'
import translationsUK from './locales/uk/common.json'
import iconFlagUK from '../images/flags/uk.svg'
import translationsRO from './locales/ro/common.json'
import iconFlagRO from '../images/flags/ro.svg'

export const LOCALES = {
    EN: 'en',
    ES: 'es',
    FR: 'fr',
    IT: 'it',
    PL: 'pl',
    RU: 'ru',
    UK: 'uk',
    ID: 'id',
    PT: 'pt',
    DE: 'de',
    NL: 'nl',
    TR: 'tr',
    KO: 'ko',
    NO: 'no',
    SV: 'sv',
    JP: 'jp',
    FI: 'fi',
    LV: 'lv',
    DA: 'da',
    RO: 'ro',
}

const LOCALES_DETAILS = [
    {
        code: LOCALES.EN,
        fullCode: 'en_US',
        deeplCode: 'en-us',
        isDeeplRequired: true,
        label: 'English',
        translations: translationsEN,
        icon: iconFlagUS,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.ES,
        fullCode: 'es_ES',
        deeplCode: 'es',
        isDeeplRequired: true,
        label: 'Español',
        translations: translationsES,
        icon: iconFlagES,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.PT,
        fullCode: 'pt_PT',
        deeplCode: 'pt-pt',
        isDeeplRequired: true,
        label: 'Português',
        translations: translationsPT,
        icon: iconFlagPT,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.FR,
        fullCode: 'fr_FR',
        deeplCode: 'fr',
        isDeeplRequired: true,
        label: 'Français',
        translations: translationsFR,
        icon: iconFlagFR,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.RU,
        fullCode: 'ru_RU',
        deeplCode: 'ru',
        isDeeplRequired: true,
        label: 'Русский',
        translations: translationsRU,
        icon: iconFlagRU,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.UK,
        fullCode: 'uk_UK',
        deeplCode: 'uk',
        isDeeplRequired: true,
        label: 'Українська',
        translations: translationsUK,
        icon: iconFlagUK,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.DE,
        fullCode: 'de_DE',
        deeplCode: 'de',
        isDeeplRequired: true,
        label: 'Deutsch',
        translations: translationsDE,
        icon: iconFlagDE,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.IT,
        fullCode: 'it_IT',
        deeplCode: 'it',
        isDeeplRequired: true,
        label: 'Italiano',
        translations: translationsIT,
        icon: iconFlagIT,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.PL,
        fullCode: 'pl_PL',
        deeplCode: 'pl',
        isDeeplRequired: true,
        label: 'Polska',
        translations: translationsPL,
        icon: iconFlagPL,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.LV,
        fullCode: 'lv_LV',
        deeplCode: 'lv',
        isDeeplRequired: true,
        label: 'Latviešu',
        translations: translationsLV,
        icon: iconFlagLV,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.NL,
        fullCode: 'nl_NL',
        deeplCode: 'nl',
        isDeeplRequired: true,
        label: 'Dutch',
        translations: translationsNL,
        icon: iconFlagNL,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.ID,
        fullCode: 'id_ID',
        deeplCode: 'id',
        isDeeplRequired: true,
        label: 'Indonesia',
        translations: translationsID,
        icon: iconFlagID,
        is_enabled: true,
        is_visible: !isEuVersion,
    },
    {
        code: LOCALES.NO,
        fullCode: 'no_NO',
        deeplCode: 'nb',
        isDeeplRequired: true,
        label: 'Norsk',
        translations: translationsNO,
        icon: iconFlagNO,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.DA,
        fullCode: 'da_DA',
        deeplCode: 'da',
        isDeeplRequired: true,
        label: 'Dansk',
        translations: translationsDA,
        icon: iconFlagDA,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.SV,
        fullCode: 'sv_SE',
        deeplCode: 'sv',
        isDeeplRequired: true,
        label: 'Svenska',
        translations: translationsSV,
        icon: iconFlagSV,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.FI,
        fullCode: 'fi_FI',
        deeplCode: 'fi',
        isDeeplRequired: true,
        label: 'Suomi',
        translations: translationsFI,
        icon: iconFlagFI,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.RO,
        fullCode: 'ro_RO',
        deeplCode: 'ro',
        isDeeplRequired: true,
        label: 'Română',
        translations: translationsRO,
        icon: iconFlagRO,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.TR,
        fullCode: 'tr_TR',
        deeplCode: 'tr',
        isDeeplRequired: true,
        label: 'Türkçe',
        translations: translationsTR,
        icon: iconFlagTR,
        is_enabled: true,
        is_visible: true,
    },
    {
        code: LOCALES.KO,
        fullCode: 'ko_KO',
        deeplCode: 'ko',
        isDeeplRequired: true,
        label: '한국어',
        translations: translationsKO,
        icon: iconFlagKO,
        is_enabled: true,
        is_visible: !isEuVersion,
    },
    {
        code: LOCALES.JP,
        fullCode: 'ja_JP',
        deeplCode: 'ja',
        isDeeplRequired: true,
        label: '日本語',
        translations: translationsJP,
        icon: iconFlagJP,
        is_enabled: true,
        is_visible: !isEuVersion,
    },
]

export const DEFAULT_LOCALE = LOCALES.EN
export const BASE_LOCALE = LOCALES.RU

export const LOCALES_PATH = 'packages/interacty-client/src/i18n/locales'

export default LOCALES_DETAILS

export const SUPPORTED_LOCALES_DETAILS = LOCALES_DETAILS.filter(locale => locale.is_enabled)

export const findLanguageByCode = code => LOCALES_DETAILS.find(lng => lng.code === code)
