import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useAssetManager } from 'components/AssetManager'
import BLOCK from 'common/constants/BlockTypes/BlocksEnum'
import { getUniqueId } from 'common/utils/getUniqueId'

import icon_addText from '../../../i/add_text.svg'
import icon_addImg from '../../../i/add_img.svg'
import icon_addButton from '../../../i/add_cta.svg'
import icon_more from '../../../i/more.svg'

import styles from './Add.module.scss'

const Add = ({ onAdd, onOpenBlockPane, isHasBlocks, isMultiplayerGame }) => {
    const { t } = useTranslation()
    const { chooseAsset } = useAssetManager(true)

    const addBlockWithImage = () => {
        const blockId = getUniqueId()
        chooseAsset(null, data => onAdd(BLOCK.image, null, { url: data.src, author: data.author }, blockId))
    }

    return (
        <div className={classNames(styles.add, { [styles.isAddNotHasBlocks]: !isHasBlocks })}>
            <ul className={styles.quickAddList}>
                <span className={styles.button} onClick={() => onAdd(BLOCK.text)}>
                    <img className={styles.buttonIcon} src={icon_addText} alt="text" />
                    {t('Add text')}
                </span>
                <span className={styles.button} onClick={addBlockWithImage}>
                    <img className={styles.buttonIcon} src={icon_addImg} alt="image" />
                    {t('Add image')}
                </span>
                <span className={styles.button} onClick={() => onAdd(BLOCK.button)}>
                    <img className={styles.buttonIcon} src={icon_addButton} alt="button" />
                    {t('Add button')}
                </span>
                <span
                    className={classNames(styles.button, styles.buttonBlue)}
                    data-test="all-blocks-panel-button"
                    onClick={() => onOpenBlockPane()}
                >
                    <img className={styles.buttonIcon} src={icon_more} alt="more" />
                    {t('All blocks')}
                </span>
            </ul>
            {!isMultiplayerGame && !isHasBlocks && (
                <div className={styles.text}>{t('Start creating your project')}</div>
            )}
        </div>
    )
}

export default Add
