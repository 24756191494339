import i18n from 'i18n'
import store from 'store'
import { API__ORGANIZATIONS, API__PROJECTS } from 'api'
import { mapOrganizationMembers } from 'pages/MyProjects/utils/mapper'
import { ALL_PROJECTS_MEMBER } from 'pages/MyProjects/utils/constants'
import Toast from 'components/Toast/Toast'

const ORGANIZATIONS__SET = organizations => {
    return {
        type: 'ORGANIZATIONS__SET',
        payload: organizations,
    }
}

export const ORGANIZATIONS__SET_LIST = organizations => {
    return {
        type: 'ORGANIZATIONS__SET_LIST',
        payload: organizations,
    }
}

export const ORGANIZATIONS__SET_SELECTED_ORGANIZATION = organizationId => {
    return {
        type: 'ORGANIZATIONS__SET_SELECTED_ORGANIZATION',
        payload: organizationId,
    }
}

export const ORGANIZATIONS__SET_MEMBERS = members => {
    return {
        type: 'ORGANIZATIONS__SET_MEMBERS',
        payload: members,
    }
}

export const ORGANIZATIONS__SET_SELECTED_MEMBER = member => {
    return {
        type: 'ORGANIZATIONS__SET_SELECTED_MEMBER',
        payload: member,
    }
}

export const ORGANIZATIONS__SET_SELECTED_SUBSCRIPTION = subscription => {
    return {
        type: 'ORGANIZATIONS__SET_SELECTED_SUBSCRIPTION',
        payload: subscription,
    }
}

export const ORGANIZATIONS__SET_SELECTED_ORGANIZATION_PROJECTS_COUNT = subscription => {
    return {
        type: 'ORGANIZATIONS__SET_SELECTED_ORGANIZATION_PROJECTS_COUNT',
        payload: subscription,
    }
}

export const ORGANIZATIONS__INIT = () => async dispatch => {
    const { user_details } = store.getState()
    return API__ORGANIZATIONS.GET_ORGANIZATION_LIST().then(async organizationList => {
        const myOrganization = organizationList.find(organization => organization.ownerId === user_details.id)
        const [organizationSubscription, organizationMembers, projectsCount] = await Promise.all([
            API__ORGANIZATIONS.GET_ORGANIZATION_SUBSCRIPTION(myOrganization.id),
            API__ORGANIZATIONS.GET_ORGANIZATION_MEMBER_LIST(myOrganization.id, false),
            API__PROJECTS.GET_PROJECTS_COUNT({ organizationId: myOrganization.id }),
        ])

        const totalProjectsCount = projectsCount.reduce((acc, val) => acc + val.count, 0)
        dispatch(
            ORGANIZATIONS__SET({
                organizationList,
                selectedOrganizationId: myOrganization.id,
                memberList: mapOrganizationMembers(organizationMembers),
                selectedMember: ALL_PROJECTS_MEMBER,
                selectedSubscription: organizationSubscription,
                selectedOrganizationProjectsCount: totalProjectsCount,
                selectedFolderId: null,
            }),
        )
    })
}

export const ORGANIZATIONS_REFRESH = () => async dispatch => {
    const organizationList = await API__ORGANIZATIONS.GET_ORGANIZATION_LIST()
    dispatch(ORGANIZATIONS__SET_LIST(organizationList))
}

export const ORGANIZATIONS__SET_ORGANIZATION_AS_SELECTED = orgId => async (dispatch, getState) => {
    const {
        organizations: { organizationList },
    } = getState()
    const organizationMembersPromise = API__ORGANIZATIONS.GET_ORGANIZATION_MEMBER_LIST(orgId, false)
    const organizationSubscriptionPromise = API__ORGANIZATIONS.GET_ORGANIZATION_SUBSCRIPTION(orgId)
    const projectsCountPromise = API__PROJECTS.GET_PROJECTS_COUNT({ organizationId: orgId })

    return Promise.all([organizationMembersPromise, organizationSubscriptionPromise, projectsCountPromise])
        .then(async result => {
            const [organizationMembersRaw, organizationSubscription, projectsCount] = result
            const organizationMembers = mapOrganizationMembers(organizationMembersRaw).sort(
                (x, y) => y.isApprove - x.isApprove,
            )
            const totalProjectsCount = projectsCount.reduce((acc, val) => acc + val.count, 0)
            dispatch(
                ORGANIZATIONS__SET({
                    organizationList: organizationList,
                    selectedOrganizationId: Number(orgId),
                    memberList: organizationMembers,
                    selectedMember: ALL_PROJECTS_MEMBER,
                    selectedSubscription: organizationSubscription,
                    selectedOrganizationProjectsCount: totalProjectsCount,
                    selectedFolderId: null,
                }),
            )
        })
        .catch(err => {
            if (err.response && err.response.status === 403) {
                dispatch(ORGANIZATIONS_REFRESH())
                Toast('info', { message: i18n.t("Looks like you've been removed from the team") })
            }
        })
}

export const ORGANIZATIONS__REFRESH_SELECTED_SUBSCRIPTION = () => async (dispatch, getState) => {
    const {
        organizations: { selectedOrganizationId },
    } = getState()
    const organizationSubscription = await API__ORGANIZATIONS.GET_ORGANIZATION_SUBSCRIPTION(selectedOrganizationId)
    dispatch(ORGANIZATIONS__SET_SELECTED_SUBSCRIPTION(organizationSubscription))
}

export const ORGANIZATIONS_REFRESH_MEMBERS = () => async (dispatch, getState) => {
    const {
        organizations: { selectedOrganizationId },
    } = getState()
    const organizationMembers = await API__ORGANIZATIONS.GET_ORGANIZATION_MEMBER_LIST(
        selectedOrganizationId,
        false,
    ).then(members => mapOrganizationMembers(members))
    dispatch(ORGANIZATIONS__SET_MEMBERS(organizationMembers))
}

export const ORGANIZATIONS_SET_ME_AS_SELECTED_MEMBER = () => async (dispatch, getState) => {
    const {
        user_details,
        organizations: { memberList },
    } = getState()
    const me = memberList.find(member => member.userId === user_details.id)
    dispatch(ORGANIZATIONS__SET_SELECTED_MEMBER(me))
}

export const ORGANIZATIONS_REFRESH_SELECTED_ORGANIZATION_PROJECTS_COUNT = () => async (dispatch, getState) => {
    const {
        organizations: { selectedOrganizationId },
    } = getState()
    const organizationProjectsCount = await API__PROJECTS.GET_PROJECTS_COUNT({
        organizationId: selectedOrganizationId,
    }).then(projectsCount => projectsCount.reduce((acc, val) => acc + val.count, 0))
    dispatch(ORGANIZATIONS__SET_SELECTED_ORGANIZATION_PROJECTS_COUNT(organizationProjectsCount))
}

export const ORGANIZATIONS__SET_SELECTED_FOLDER_ID = folderId => async dispatch => {
    dispatch({ type: 'ORGANIZATIONS__SET_SELECTED_FOLDER_ID', payload: folderId })
}
