import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { isAppSumoUser } from 'common/utils/permissions'

import { PRODUCT_LIST } from 'common/constants/ProductConstants'

import { getDate } from 'utils/dateTime'
import { getFormattedPrice } from 'utils/subscription'

import { formatBillingCycle, formatExpirationDate } from '../../../../../../utils'
import { PAYMENT_METHOD_CODES } from '../../../../../../constants'

import PaymentMethod from '../../../../../../components/PaymentMethod/PaymentMethod'

import iconSumoling from './i/sumoling.svg'
import iconInfinity from './i/infinity.svg'

import ActiveSection from './components/ActiveSection/ActiveSection'
import CancelSubscriptionModal from './components/CancelSubscriptionModal/CancelSubscriptionModal'
import Marks from './components/Marks/Marks'

const Item = ({ subscription, onUpdateRecurringOption, isLoading, isProjectSubscription, isWaiting }) => {
    const { t } = useTranslation()

    const [isShowCancelSubscriptionModal, setIsShowCancelSubscriptionModal] = useState(false)

    const {
        expirationDate,
        product: { billingCycleType, billingCycleValue, name: productName, purchasePrice, currency, productCode },
        lastPayment,
        started,
        isLifetime,
        recurringEnabled,
        selected,
        subscriptionReference,
        status,
        expired,
    } = subscription

    const canControlRecurring =
        !expired &&
        status === 'ACTIVE' &&
        selected &&
        subscriptionReference &&
        lastPayment !== null &&
        lastPayment.paymentMethodCode !== PAYMENT_METHOD_CODES.FREE &&
        (productCode !== PRODUCT_LIST.Semester || (productCode === PRODUCT_LIST.Semester && recurringEnabled))

    const isAppSumoUserAndNotProjectSubscription = isAppSumoUser() && !isProjectSubscription
    const isNotAppSumoUserOrProjectSubscription = !isAppSumoUser() || isProjectSubscription

    return (
        <>
            <div className="subscriptions__state__row">
                <div className="subscriptions__state__row__main">
                    <div className="subscriptions__state__item">
                        <span className="subscriptions__state__item-title">{t('Subscription')}</span>
                        <p className="subscriptions__state__item-active-value">
                            {isAppSumoUserAndNotProjectSubscription ? productName : productName.split(' ')[0]}
                        </p>
                    </div>
                    {isAppSumoUserAndNotProjectSubscription && (
                        <div className="subscriptions__state__item">
                            <span className="subscriptions__state__item-title">{t('Price')}</span>
                            <p className="subscriptions__state__item-value">
                                {getFormattedPrice(purchasePrice, currency)}
                            </p>
                        </div>
                    )}
                    {isNotAppSumoUserOrProjectSubscription && (
                        <>
                            <div className="subscriptions__state__item">
                                <span className="subscriptions__state__item-title">{t('Price')}</span>
                                <p className="subscriptions__state__item-value">
                                    {lastPayment
                                        ? getFormattedPrice(lastPayment.price, lastPayment.currency)
                                        : t('N/A')}
                                </p>
                            </div>
                            <div className="subscriptions__state__item">
                                <span className="subscriptions__state__item-title">{t('Cycle')}</span>
                                <p className="subscriptions__state__item-value">
                                    {formatBillingCycle(billingCycleType, billingCycleValue)}
                                </p>
                            </div>
                        </>
                    )}
                    <div className="subscriptions__state__item">
                        <span className="subscriptions__state__item-title">{t('Started')}</span>
                        <p className="subscriptions__state__item-value">{getDate(started)}</p>
                    </div>
                    {isNotAppSumoUserOrProjectSubscription && (
                        <>
                            <div className="subscriptions__state__item">
                                <span className="subscriptions__state__item-title">{t('Last payment')}</span>
                                <p className="subscriptions__state__item-value">
                                    {lastPayment ? getDate(lastPayment.saleDate) : 'N/A'}
                                </p>
                            </div>
                            <div className="subscriptions__state__item">
                                <span className="subscriptions__state__item-title">{t('Payment method')}</span>
                                <PaymentMethod
                                    method={lastPayment?.paymentMethod}
                                    digits={lastPayment?.cardLastDigits}
                                />
                            </div>
                            {!isLifetime && (
                                <ActiveSection
                                    productCode={productCode}
                                    recurringEnabled={recurringEnabled}
                                    expirationDate={expirationDate}
                                    billingCycleValue={billingCycleValue}
                                    canControlRecurring={canControlRecurring}
                                    isLoading={isLoading}
                                    isWaiting={isWaiting}
                                />
                            )}
                        </>
                    )}
                    {isAppSumoUserAndNotProjectSubscription && (
                        <>
                            <div className="subscriptions__state__item subscriptions__state__item-appsumo-marks">
                                <Marks
                                    data={[
                                        {
                                            text: t('Special tariff plan for Sumo-lings'),
                                            iconUrl: iconSumoling,
                                            isFilled: true,
                                            isReversed: true,
                                        },
                                        {
                                            text: t('Lifetime'),
                                            iconUrl: iconInfinity,
                                            iconBackground: '#65BB5A',
                                        },
                                    ]}
                                />
                            </div>
                        </>
                    )}
                </div>
                {canControlRecurring &&
                    (recurringEnabled ? (
                        <div className="subscriptions__state__row__info  subscriptions__state__row__info--renewal">
                            <p>
                                {t('Your subscription will automatically renew on {{date}}', {
                                    date: formatExpirationDate(billingCycleValue, expirationDate),
                                })}
                            </p>
                            <div
                                className={classNames('subscriptions__state__row__info__cancel-btn', {
                                    'subscriptions__state__row__info__cancel-btn--loading': isLoading,
                                })}
                                onClick={() => setIsShowCancelSubscriptionModal(true)}
                            >
                                {t('Disable auto-renewal')}
                            </div>
                        </div>
                    ) : (
                        <div className="subscriptions__state__row__info subscriptions__state__row__info--renewal">
                            <p>
                                {t('Auto-renewal is disabled. Your subscription is active until {{date}}', {
                                    date: formatExpirationDate(billingCycleValue, expirationDate),
                                })}
                            </p>
                            <div
                                className={classNames('subscriptions__state__row__info__cancel-btn', {
                                    'subscriptions__state__row__info__cancel-btn--loading': isLoading,
                                })}
                                onClick={() => onUpdateRecurringOption(subscription, true)}
                            >
                                {t('Renew subscription ')}
                            </div>
                        </div>
                    ))}
                {isWaiting && (
                    <div className="subscriptions__state__row__text">
                        {t('You can return to this tariff plan before it expires.')}{' '}
                        <a
                            href="https://help.interacty.me/en/content/how-do-interacty-subscriptions-work"
                            target="_blank"
                        >
                            {t('How it works')}
                        </a>
                    </div>
                )}
            </div>

            {isShowCancelSubscriptionModal && (
                <CancelSubscriptionModal
                    onCancel={data => {
                        onUpdateRecurringOption(subscription, false, data)
                        setIsShowCancelSubscriptionModal(false)
                    }}
                    onClose={() => setIsShowCancelSubscriptionModal(false)}
                />
            )}
        </>
    )
}

export default Item
