import React from 'react'

import i18n from 'i18n'

import { getDate } from 'utils/dateTime'
import { getFormattedPrice } from 'utils/subscription'

import BILLING_SYSTEMS from 'common/constants/billingSystems'

import PaymentMethod from '../../components/PaymentMethod/PaymentMethod'

import { PAYMENT_METHOD_CODES } from '../../constants'

import NoSubscription from './components/NoSubscription/NoSubscription'

import './BillingHistory.scss'

const getPrice = item => {
    if (item.paymentMethodCode === PAYMENT_METHOD_CODES.FREE) return '0'
    return getFormattedPrice(item.total, item.currency)
}

const columns = [
    {
        label: i18n.t('Date'),
        width: 15,
        component: ({ item }) => <div>{getDate(item.saleDate)}</div>,
    },
    {
        label: i18n.t('Product name'),
        width: 25,
        component: ({ item }) => <span className="dark">{item.product.name}</span>,
    },
    {
        label: i18n.t('Payment method'),
        width: 15,
        component: ({ item }) => <PaymentMethod method={item.paymentMethod} digits={item.cardLastDigits} isHideTitle />,
    },
    {
        label: i18n.t('Status'),
        width: 20,
        component: ({ item }) => item.orderStatus,
    },
    {
        label: i18n.t('Total'),
        width: 10,
        component: ({ item }) => <span className="dark">{getPrice(item)}</span>,
    },
    {
        label: '',
        width: 15,
        component: ({ item, getInvoice }) => {
            if (item.billingSystem !== BILLING_SYSTEMS.STRIPE || item.orderStatus !== 'COMPLETE') return null

            return (
                <a className="invoice-link" target="_blank" rel="noopener noreferrer" onClick={() => getInvoice(item)}>
                    {i18n.t('View invoice')}
                </a>
            )
        },
    },
]

const BillingHistory = ({ payments, getInvoice, isLoading }) => {
    if (isLoading) return null

    return (
        <div className="billing-history">
            {payments.content.length ? (
                <div className="billing-history-table">
                    <ul className="table-header">
                        {columns.map((item, i) => (
                            <li key={i} className="col" style={{ width: `${item.width}%` }}>
                                {item.label}
                            </li>
                        ))}
                    </ul>
                    <ul className="table-list">
                        {payments.content.map((item, i) => (
                            <li key={i} className="table-list__item">
                                <ul>
                                    {columns.map((column, _i) => (
                                        <li key={_i} className="col" style={{ width: `${column.width}%` }}>
                                            <div className="label">{column.label}</div>
                                            {column.component({ item, getInvoice })}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <NoSubscription />
            )}
        </div>
    )
}

export default BillingHistory
