import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import accounting from 'accounting'

import { formattedTime } from 'utils/formatter'
import useRouter from 'hooks/useRouter'
import useUserSubscriptions from 'hooks/useUserSubscriptions'

import { getConstraintLimit, isAppSumoUser } from 'common/utils/permissions'

import { FEATURE_TYPES } from 'common/constants/subscription'

import Item from './components/Item/Item'

import './ProjectStatistic.scss'

const getConstraint = (constraints, featureType) => constraints[featureType] || null

const ProjectStatistic = ({ project, isMultiplayer }) => {
    const { t } = useTranslation()
    const { constraints } = useUserSubscriptions()
    const { historyPush } = useRouter()

    const [maxPlayersCount, setMaxPlayersCount] = useState(0)

    useEffect(() => {
        if (!constraints) return
        const multiplayerConstraint = getConstraint(constraints, FEATURE_TYPES.MULTIPLAYER_PLAYERS)
        if (!multiplayerConstraint) return
        setMaxPlayersCount(getConstraintLimit(multiplayerConstraint))
    }, [constraints])

    return (
        <ul className="project-block__statistic">
            {isMultiplayer ? (
                <>
                    <Item label={t('Rounds')} value={accounting.formatNumber(project.roundsCount, { thousand: ' ' })} />
                    {!!maxPlayersCount && !isAppSumoUser() && (
                        <Item
                            label={t('Max Players')}
                            labelButtonText={t('Add more')}
                            onLabelButtonClick={() => historyPush('/billing-and-payments?tab=subscription')}
                            value={accounting.formatNumber(maxPlayersCount, { thousand: ' ' })}
                        />
                    )}
                </>
            ) : (
                <>
                    <Item label={t('Views')} value={accounting.formatNumber(project.sessionCount, { thousand: ' ' })} />
                    <Item
                        label={t('Users')}
                        value={accounting.formatNumber(project.uniqueUsersCount, { thousand: ' ' })}
                    />
                    <Item label={t('Average attention time')} value={formattedTime(project.avgSessionTime)} />
                </>
            )}
        </ul>
    )
}

export default ProjectStatistic
