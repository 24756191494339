import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { API__USER } from 'api'

import { USER__SET_SUBSCRIPTIONS } from 'store/actions'

const convertConstraints = arr => Object.fromEntries(arr.map(datum => [datum.featureProduct.featureType, datum]))

const useUserSubscriptions = () => {
    const { user_details, user_subscriptions, organizations } = useSelector(state => state)
    const dispatch = useDispatch()
    const [constraints, setConstraints] = useState(null)

    useEffect(() => {
        if (!organizations.selectedSubscription?.constraints) return
        setConstraints(convertConstraints(organizations.selectedSubscription.constraints))
    }, [organizations.selectedSubscription])

    useEffect(() => {
        const fetch = async () => dispatch(USER__SET_SUBSCRIPTIONS(await API__USER.GET_SUBSCRIPTIONS(user_details.id)))

        if (!user_subscriptions) fetch()
    }, [user_details, user_subscriptions, dispatch])

    return { subscriptions: user_subscriptions, constraints }
}

export default useUserSubscriptions
