import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useInitialLoading from 'hooks/useInitialLoading'

import { API__USER } from 'api'

import { isEducation } from 'common/utils/permissions'

import { USER__SET_SUBSCRIPTIONS, USER__SET_PUBLIC_PROFILE } from 'store/actions'

const UserInitializer = ({ userId, children }) => {
    const { isReady, onReady } = useInitialLoading()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetch = async () => {
            await Promise.all([getUserSubscriptions(), getUserPublicProfile()])
            onReady()
        }
        fetch()
    }, [userId])

    const getUserSubscriptions = async () => {
        try {
            const response = await API__USER.GET_SUBSCRIPTIONS(userId)
            await dispatch(USER__SET_SUBSCRIPTIONS(response))
        } catch (err) {
            console.error(err)
        }
    }
    const getUserPublicProfile = async () => {
        try {
            if (!isEducation()) return
            const response = await API__USER.GET_PUBLIC_PROFILE(userId)
            await dispatch(USER__SET_PUBLIC_PROFILE(response))
        } catch (err) {
            console.error(err)
        }
    }

    if (!isReady) return null

    return children
}

export default UserInitializer
