export const API_URL = process.env.REACT_APP_BACKEND_URL
export const MULTIPLAYER_WS_URL = process.env.REACT_APP_MULTIPLAYER_WS_URL
export const CDN_URL = process.env.REACT_APP_CDN_URL
export const TRIAL_PRODUCT_CODE = '6531GA9GB2'
export const MAX_REFRESH_SESSION_AWAITING = 15 * 60 * 1000 // 15 min
export const DEFAULT_LOCALE_CODE = 'en'

export const SSR_SUPPORTED_LOCALES = [
    DEFAULT_LOCALE_CODE,
    'ru',
    'uk',
    'es',
    'pt',
    'fr',
    'de',
    'it',
    'nl',
    'pl',
    'id',
    'tr',
    'ko',
    'no',
    'sv',
    'jp',
    'fi',
    'ro',
    'lv',
    'da',
]

export const API_EVENTS = {
    IFRAME_INIT: 'IFRAME_INIT',
    IFRAME_ONLOAD: 'IFRAME_ONLOAD',
    REMIX_INIT: 'REMIX_INIT',
    REMIX_ONLOAD: 'REMIX_ONLOAD',
    REDIRECT: 'REDIRECT',
    ACTION: 'ACTION',
    CHANGE_SIZE: 'CHANGE_SIZE',
    ERROR: 'ERROR',
    ACTIVITY: 'ACTIVITY',
    SCROLL_PARENT: 'SCROLL_PARENT',
}

export const MODES = {
    PUBLISHED: 'published',
    PREVIEW: 'preview',
    EMERGENCY: 'emergency',
}

export const PROJECT_MODES = {
    SINGLE: 'SINGLE',
    MULTIPLAYER: 'MULTIPLAYER',
}

export const CONSTRUCTOR_VALIDATION_ERROR_TYPES = {
    FORMAT: 'format',
    VALUE: 'value',
    UNKNOWN: 'unknown',
    UNDEFINED: 'undefined',
    INTERNAL: 'internal',
}

export const CONSTRUCTOR_PARAMS = {
    MODE: 'mode',
    PROJECT_MODE: 'projectMode',
    NODE_ELEMENT: 'nodeElement',
    REMIX_URL: 'remixUrl',
    FEATURES: 'features',
    PROJECT_ID: 'projectId',
    PROJECT_NUMERIC_ID: 'projectNumericId',
    PROJECT_STRUCTURE: 'projectStructure',
    INITIAL_WIDTH: 'initialWidth',
    INITIAL_HEIGHT: 'initialHeight',
    LANGUAGE: 'lng',
    ADDITIONAL_TOP_OFFSET: 'additionalTopOffset',
    ON_EVENT: 'onEvent',
    SUBSCRIPTION_PRODUCT_CODE: 'subscriptionProductCode',
}

export const URL_PARAMS = {
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CONTENT: 'utm_content',
}
export const LOAD_ELEMENT_PARAMS = {
    PROJECT_CLASSNAME: 'remix-app',
    INITIALIZED_ATTRIBUTE_NAME: 'initialized',
    HASH_ATTRIBUTE_NAME: 'hash',
}
export const POST_MESSAGE_METHODS = {
    WINDOW_SIZE: 'windowSize',
    IFRAME_POSITION: 'iframePosition',
    INIT_ERROR: 'initError',
    INITIALIZED: 'initialized',
    ACTIVITY: 'activity',
    SET_SIZE: 'setSize',
    SCROLL_PARENT: 'scrollParent',
    CONNECT_WS: 'connect-ws',
    DISCONNECT_WS: 'disconnect-ws',
    SEND_WS_MESSAGE: 'send-ws-message',
    SEND_REQUEST: 'send-request',
    REDIRECT: 'redirect',
    WS_CONNECTED: 'ws-connected',
    WS_DISCONNECTED: 'ws-disconnected',
    WS_MESSAGE: 'ws-message',
    SEND_RESPONSE: 'send-response',
    INIT: 'init',
    SESSION_CREATED: 'sessionCreated',
    ACTION: 'action',
    KEYDOWN: 'keyDown',
}
