const TRIAL_PRODUCT_CODE = '6531GA9GB2'

export const EDUCATION_GROUP_ID = 'EDUCATION'

export const PRODUCT_LIST = {
    Trial: TRIAL_PRODUCT_CODE,
    Free: '7D6DCC0440',
    Starter: '2444NE4LB5',
    StarterYear: '2444NE4LB5_YEAR',
    Basic: 'E8F83FBF8E',
    BasicYear: 'E8F83FBF8E_YEAR',
    Professional: '29D7A9C4F0',
    ProfessionalYear: '29D7A9C4F0_YEAR',
    ProfessionalPlus: '35D7A9C4DB',
    ProfessionalPlusYear: '35D7A9C4DB_YEAR',
    Enterprise: 'E721588D11',
    EnterpriseYear: 'E721588D11_YEAR',
    Organizations: '35D7A9C4D1',
    OrganizationsYear: '35D7A9C4D1_YEAR',
    OneTimeWhitelabel: 'NYII0AS0I4',
    OneTimeGamification: 'XOTEE7HTVX',
    OneTimeEvent: '3K0XQKIWLW',
    Semester: 'VPQ3RKVNEO_SEMESTER',
}

export const PRODUCT_LIST_FREE = {
    Trial: TRIAL_PRODUCT_CODE,
    Free: '7D6DCC0440',
    EducationFree: 'M0AC33DDDP',
}
