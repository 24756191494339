import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useRouter from 'hooks/useRouter'
import useUserSubscriptions from 'hooks/useUserSubscriptions'

import Button from 'components/Form/Button/Button'
import Toast from 'components/Toast/Toast'

import { FEATURE_TYPES } from 'common/constants/subscription'

import { getConstraintLimit } from 'common/utils/permissions'

import { API__PROJECTS } from 'api'

import ModeCard from './components/ModeCard/ModeCard'
import RangeSlider from './components/RangeSlider/RangeSlider'
import RoundsTime from './components/RoundsTime/RoundsTime'

import hostImage from './i/host.png'
import autoImage from './i/auto.png'
import upgradeSvg from './i/upgrade.svg'

import { MODES, DEFAULT_ROUND_TIME, ROUND_RESULT_TIME } from './constants'

import { getConstraint, getMultiplayerGameData } from './utils'

import styles from './Creation.module.scss'

const Creation = ({ project, onContinue }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState(MODES.AUTO)
    const [maxPlayersCount, setMaxPlayersCount] = useState(0)
    const [playersCount, setPlayersCount] = useState(1)
    const [presets, setPresets] = useState(new Array(project.roundsCount).fill(DEFAULT_ROUND_TIME))

    const { historyPush } = useRouter()
    const { constraints } = useUserSubscriptions()

    useEffect(() => {
        if (!constraints) return
        const multiplayerConstraint = getConstraint(constraints, FEATURE_TYPES.MULTIPLAYER_PLAYERS)
        if (!multiplayerConstraint) return
        setMaxPlayersCount(getConstraintLimit(multiplayerConstraint))
    }, [constraints])

    const onCreate = async () => {
        try {
            setIsLoading(true)
            await API__PROJECTS.CREATE_MULTIPLAYER_GAME(
                project.id,
                getMultiplayerGameData({
                    projectId: project.id,
                    name: t('My Game'),
                    isHasLeader: mode === MODES.CLASSIC,
                    playersCount,
                    projectStructureJson: project.projectStructureJson,
                    maxRoundResultTime: ROUND_RESULT_TIME,
                    maxRoundsTimeArray: presets,
                    isStartNow: true,
                }),
            )
            const newProject = await API__PROJECTS.GET_PROJECT(project.id)
            onContinue(newProject)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.creation}>
            <div className={styles.main}>
                <ul className={styles.modeSelector}>
                    <ModeCard
                        title={t('Play without a host')}
                        description={t('Game with automatic start and pre-set time')}
                        image={autoImage}
                        isSelected={mode === MODES.AUTO}
                        onSelect={() => setMode(MODES.AUTO)}
                    />
                    <ModeCard
                        title={t('Classic mode')}
                        description={t('You will be a host')}
                        image={hostImage}
                        isSelected={mode === MODES.CLASSIC}
                        onSelect={() => setMode(MODES.CLASSIC)}
                    />
                </ul>
                {mode === MODES.AUTO && <RoundsTime presets={presets} onSave={newPresets => setPresets(newPresets)} />}
                {!!maxPlayersCount && (
                    <div className={styles.rangeSliderBox}>
                        <RangeSlider
                            mode={mode}
                            value={playersCount}
                            onChange={v => setPlayersCount(v)}
                            max={maxPlayersCount}
                            title={
                                <>
                                    {mode === MODES.CLASSIC && t('Maximum number of players')}
                                    {mode === MODES.AUTO && t('Number of players')}
                                </>
                            }
                            noteText={
                                <>
                                    {mode === MODES.CLASSIC &&
                                        t('Up to {{count}} participants per session are available on your plan.', {
                                            count: maxPlayersCount,
                                        })}
                                    {mode === MODES.AUTO &&
                                        t(
                                            'Enter the exact number of players. The game will automatically start when all participants join the game',
                                        )}
                                </>
                            }
                        />
                    </div>
                )}
            </div>
            <div className={styles.buttons}>
                <Button
                    className={styles.upgradeButton}
                    backgroundColor="transparent"
                    borderColor={'#785CC7'}
                    color={'#785CC7'}
                    onClick={() => historyPush('/billing-and-payments?tab=subscription')}
                >
                    <img src={upgradeSvg} alt="" />
                    {t('Upgrade for more gamers')}
                </Button>
                <Button onClick={onCreate} isDisabled={isLoading}>
                    {t('Continue')}
                </Button>
            </div>
        </div>
    )
}

export default Creation
